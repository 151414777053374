<template>
  <div
    v-if="contentListType=='consumptiontoc'"
    class="contentlist itemsstart w-full"
  >
    <ConsumptionTocRenderer :content="content" />
  </div>
  <div
    class="contentlist w-full"
    v-else-if="contentListType=='rawhtml'"
  >
    <HtmlRenderer :content="content" />
  </div>
  <div
    v-else
    class="contentlist w-full"
    v-for="(section, i) in content"
    v-bind:key="i"
  >
    <h1
      v-if="section.section_head"
      class="
        text-2xl
        font-sans font-bold
        text-center
        md:text-left
        pt-5
        md:pt-0
        pb-2
        md:pb-5
        text-minimal-heavypurple
      "
    >
      {{ section.section_head }}
    </h1>
    <ListRendererTeaching
      v-if="contentListType == 'teaching'"
      :items="section.items"
    />
    <ListRenderer
      v-else
      :items="section.items"
    />
  </div>
</template>

<script>
import ListRenderer from "./ListRenderer.vue"
import ListRendererTeaching from "./ListRendererTeaching.vue"
import HtmlRenderer from "./HtmlRenderer.vue"
import ConsumptionTocRenderer from "./ConsumptionTocRenderer.vue"

export default {
  name: 'ContentList',
  components: {
    ListRenderer, ListRendererTeaching, HtmlRenderer, ConsumptionTocRenderer
  },
  props: {
    content: Object,
    contentListType: String
  },
}
</script>

<style scoped>
.contentlist.itemsstart {
  place-self: start;
}
</style>
