<template>
    <div
      v-for="(item, idx) in items"
      :key="idx"
      class="mb-4 py-5 px-6 bg-minimal-brightgray rounded-md font-lato"
    >
      <h1 class="text-xl font-bold pb-3">{{ item.title }}</h1>
      <div class="pt-1" v-for="(d, i) in item.downloads" :key="i">
        <a
          v-if="d.doc != null"
          :href="`${publicPath}/files/Teaching/${d.doc}`"
          class="
            text-md
            hover:text-minimal-icecold
            text-minimal-purplepain
            transition-colors
            duration-100
          "
        >
          <font-awesome-icon icon="file-pdf" />
          <span class="pl-2">{{ d.semester }}</span>
        </a>
        <div v-else class="text-md leading-5 block">
          {{ d.semester }}
        </div>
      </div>
    </div>
</template>


<script>
// import ContentButton from "./ContentButton.vue"

export default {
  name: 'ListRendererTeaching',
  components: {
    // ContentButton
  },
  props: {
    items: Object
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      // mkpath: (file) => `${publicPath}/files/${file}`
    }
  }
}
</script>

