<template>
    <div
      v-for="(item, idx) in items"
      v-bind:key="idx"
      class="mb-4 py-5 px-6 bg-minimal-brightgray rounded-md font-lato"
    >
      <h1 class="text-xl font-bold">
        <span class="pr-4 text-minimal-midgray font-light">
          {{ items.length - idx }}
        </span>
        <span>
          {{ item.title }}
        </span>
      </h1>
      <h2 class="pt-1 text-mood-black">
        {{ item.sub }}
      </h2>
      <div class="flex flex-col lg:flex-row justify-start mt-6">
        <ContentButton
          v-for="(l, i) in item.links"
          v-bind:key="i"
          :label="l.text"
          :url="l.target"
        />
      </div>
      <div class="additional" v-if="item.additional">
        <div
          class="additional-item pt-4"
          v-for="(additem, i) in item.additional"
          v-bind:key="i"
        >
          <a
            class="text-sm hover:text-minimal-icecold leading-5 inline-block"
            :href="additem.target"
            >
            <font-awesome-icon icon="external-link-alt" />
            {{ additem.text }}</a
          >
          <div class="text-minimal-midgray text-xs">{{additem.source}}</div>
        </div>
      </div>
    </div>
</template>


<script>
import ContentButton from "./ContentButton.vue"

export default {
  name: 'ListRenderer',
  components: {
    ContentButton
  },
  props: {
    items: Object
  },
}
</script>

