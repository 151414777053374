import { createApp } from 'vue'
// import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPrint, faUser, faFile, faLink, faArchive, faPaperclip, faCopy, faUserFriends, faGraduationCap, faFileArchive, faFilePdf, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPrint, faUser, faFile, faLink, faArchive, faPaperclip, faCopy, faUserFriends, faGraduationCap, faFileArchive, faFilePdf, faLink, faExternalLinkAlt)

import './assets/tailwind.css'

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)
app.mount('#app')
