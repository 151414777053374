<template>
  <div
    class="
      box
      flex
      justify-center
      hover:bg-minimal-icecold
      text-minimal-heavypurple
      border-t border-minimal-heavypurple
      hover:border-minimal-heavypurple
      cursor-pointer
      transition-colors
      duration-200
    "
    :class="selected ? 'bg-minimal-icecold' : 'bg-minimal-freezepurple'"
  >
    <div class="self-center text-4xl md:text-6xl font-bebas">
      {{ label }}
    </div>
  </div>
</template>


<script>
export default {
  name: 'MenuBox',
  props: {
    label: String,
    selected: Boolean
  },

  data() {
    return {
      isHovering: false,
    }
  },
}
</script>

<style lang="scss">
</style>
