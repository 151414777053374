<template>
  <a
    :href="url"
    target="_blank"
    class="
      lg:text-md
      mb-1
      lg:mb-0
      lg:mr-2
      p-1
      md:p-2
      text-minimal-heavypurple
      hover:text-minimal-icecold
      transition-colors
      duration-100
    "
  >
    <font-awesome-icon :icon="getIcon" />
    <span class="pl-2">{{ label }}</span>
  </a>
</template>


<script>

export default {
  name: 'ContentButton',
  props: {
    label: String,
    url: String,
    linkType: String,
  },

  computed: {
    getIcon() {
      if (this.label.startsWith('Replication files'))
        return ['fas', 'paperclip'];
      if (this.label.startsWith('Supplementary files'))
        return ['fas', 'paperclip'];
      if (this.label.startsWith('Cited by'))
        return ['fas', 'user-friends'];

      return ['fas', 'file'];
    }
  }
}
</script>
