<template>
  <div
    class="cont py-3"
    v-html="content.content"
  ></div>
</template>


<script>
export default {
  name: 'HtmlRenderer',
  props: {
    content: Object
  },
}
</script>
<style scoped>
.cont {
  @apply text-xl font-lato;
}

.cont >>> .consumption-item {
  @apply  pl-4 my-3;
}

.cont >>> .consumption-item ul {
  @apply pl-12;
  list-style-type: disc;
}

.cont >>> .consumption-item h1 {
  color: green;
}
.cont >>> .consumption-item h2 {
  @apply py-4 text-minimal-midgray uppercase font-bold;
}

.cont >>> .consumption-item .tstamp {
  display: inline;
  @apply text-xl;
}
.cont >>> .consumption-item .title {
  display: inline;
  @apply pl-4;
}
.cont >>> p {
  @apply pt-4;
}
.cont >>> a {
  @apply hover:text-minimal-icecold text-minimal-purplepain transition-colors duration-100;
}
.cont >>> .box-outer-flex-container {
  @apply md:flex flex-row justify-center mt-8;
}

.cont >>> .box-inner-flex-container {
  @apply flex flex-col items-center rounded-md bg-minimal-brightgray p-4;
}

.cont >>> .box-header {
  @apply text-center text-2xl md:text-4xl pb-4 font-bebas;
}

.cont >>> .box-content-l {
  @apply flex flex-col lg:flex-row justify-center text-lg;
}

.cont >>> .box-content-r {
  @apply ml-0 lg:ml-8 pl-0 lg:pl-4 pt-4 lg:pt-0 flex flex-col justify-center lg:border-l;
}
.cont >>> .box-content-r a {
  @apply py-5 md:py-0;
}
.cont >>> h1 {
  @apply text-4xl text-minimal-heavypurple;
}
</style>

