<template>
  <OuterGrid />
</template>



<script>
/* eslint-disable */
import OuterGrid from "./components/OuterGrid.vue"
import Header from "./components/ListRenderer.vue"

export default {
  name: "App",
  components: {
    OuterGrid,
    Header
  },
  mounted() {

  }

};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lato:wght@300;400;700&family=Montserrat:ital,wght@0,400;1,700&display=swap');

</style>
