<template>
    <div class="container py-3">
      <div class="toc">
        <div
          v-for="section, i in content.content"
          :key="i"
          class="tocsection"
        >
          <div class="sectiontitle">{{section.section_title}}</div>
          <a
            v-for="item, j in section.items"
            :key="j"
            class="tocitem hover:text-minimal-icecold block "
            @click="clickHandler(item.src)"
            href="#"
          >
          <!-- <div class="tstamp">
            {{ item.tstamp }}
          </div> -->
          <div class="title">
            {{ item.title }}
          </div>
          </a>
        </div>
      </div>

      <div
        v-if="fetchingMD"
        class="content-container grid place-items-center"
      ><img :src="require('@/assets/oval.svg')" />
      </div>
      <div
        v-else
        class="mdd pl-4"
        v-html="mdcontent"
      ></div>
    </div>
</template>


<script>
import { apiURL } from "./api"

export default {
  name: 'ConsumptionTocRenderer',

  props: {
    content: Object
  },
  data() {
    return {
      detailItem: null,
      mdcontent: "",
      fetchingMD: false
    }
  },
  methods: {

    async loadContent(endpoint) {
      this.fetchingMD = true
      try {
        const res = await this.axios.get(apiURL(endpoint))
        this.mdcontent = res.data.content
        this.fetchingMD = false
      }
      catch (err) {
        if (err.response) {
          console.log(`ERROR REQUEST: ${err.response.status}`)
        }
      }
    },

    clickHandler(src) {
      this.loadContent(`consumption/${src}`)
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  align-self: flex-start;
}

.tocsection {
  @apply font-lato;

  &:not(:first-child) {
    @apply mt-8;
  }

  &::v-deep .sectiontitle {
    @apply text-2xl;
  }
}

.tocitem {
  @apply pl-4 py-1 text-lg font-lato;

  &::v-deep .title {
    font-size: 100%;
  }
  &::v-deep .tstamp {
    font-size: 80%;
  }
}

.mdd {
  @apply font-lato;

  &::v-deep h1 {
    /* font-size: 180%; */
    @apply text-minimal-heavypurple pb-4 text-xl sm:text-3xl font-bold;
  }

  &::v-deep h2 {
    font-size: 125%;
    @apply py-6;
  }

  &::v-deep ul {
    @apply pl-10;
    list-style-type: disc;
  }
  &::v-deep .tstamp {
    font-size: 80%;
  }
}

  </style>

