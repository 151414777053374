<template>
  <div class="outer-container">
    <main>
      <div class="outergrid">
        <header class="
            col-start-1
            row-start-1
            md:col-span-2
            flex
            w-full
            items-center
            justify-center
            bg-minimal-heavypurple
            text-mood-white
            font-monts
            text-md
            md:text-3xl
            uppercase
            md:py-5
          ">
          <span class="text-white border-l border-t border-r border-b px-3 py-2">Tobias Heinrich</span>
        </header>

        <div class="md:grid col-start-1 row-start-2 gap-0">
          <MenuBox
            label="Publications"
            :selected="activeMenuBox == 0"
            @click="
              loadContent('content/publications');
              activeMenuBox = 0;
              contentListType = 'default';
            "
          />
          <MenuBox
            label="ONGOING WORK"
            :selected="activeMenuBox == 1"
            @click="
              loadContent('content/ongoingwork');
              activeMenuBox = 1;
              contentListType = 'default';
            "
          />
          <MenuBox
            label="TEACHING"
            :selected="activeMenuBox == 2"
            @click="
              loadContent('content/teaching');
              activeMenuBox = 2;
              contentListType = 'teaching';
            "
          />
          <MenuBox
            label="CV"
            :selected="activeMenuBox == 3"
            @click="
              loadContent('page/cv');
              activeMenuBox = 3;
              contentListType = 'rawhtml';
            "
          />
          <MenuBox
            label="ABOUT ME"
            :selected="activeMenuBox == 4"
            @click="
              loadContent('page/about');
              activeMenuBox = 4;
              contentListType = 'rawhtml';
            "
          />
          <!-- <MenuBox
            label="CONSUMPTION"
            :selected="activeMenuBox == 5"
            @click="
              loadContent('consumptiontoc');
              activeMenuBox = 5;
              contentListType = 'consumptiontoc';
            "
          /> -->
        </div>

        <div
          v-if="fetching"
          class="content-container grid place-items-center"
        >
          <img :src="require('@/assets/oval.svg')" />
        </div>
        <div
          v-else
          class="
            content-container
            grid-col:1
            md:grid-col:2 md:grid-row:3

            p-2
            md:p-6
            overflow-y-scroll
            grid
            place-items-center
          "
        >
          <ContentList
            :content="content"
            :contentListType="contentListType"
          />
        </div>
      </div>
    </main>
  </div>
</template>




<script>
/* eslint-disable */
import MenuBox from "./MenuBox.vue"
import ContentList from "./ContentList.vue"
import { inject } from 'vue'
import { apiURL } from "./api"

export default {
  name: 'OuterGrid',
  setup() {
    const axios = inject('axios')

  },
  mounted() {
    // this.loadContent('consumptiontoc');
    this.loadContent('page/about');

    this.activeMenuBox = 4;
    this.contentListType = 'rawhtml';
  },
  components: {
    MenuBox,
    ContentList
  },
  data() {
    return {
      fetching: false,
      content: {},
      activeMenuBox: -1,
      contentListType: ""
    }
  },
  methods: {
    async loadContent(endpoint) {
      this.fetching = true
      try {
        const res = await this.axios.get(apiURL(endpoint))
        this.content = res.data
        this.fetching = false
      }
      catch (err) {
        if (err.response) {
          console.log(`ERROR REQUEST: ${err.response.status}`)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.outergrid {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 2fr;
  }

  grid-template-rows: 50px auto 1fr;
  @media (min-width: 768px) {
    grid-template-rows: 90px 1fr;
  }

  height: 100vh;
}
</style>
